<script>
  import { createEventDispatcher } from 'svelte';

  export let displayModal = false;
  export let event;

  const dispatch = createEventDispatcher();
  let activeUpload = false;
  let currentFile = 1;
  let uploadProgress = 0;
  let uploader;
  let files = [];

  async function getPresignedUrls() {
    let responses = [];
    for (let file of files) {
      const queryParams = new URLSearchParams({ filename: file.name, event_id: event.id });
      const response = await fetch(`/media/upload/?${ queryParams }`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      responses.push((await response.json()).presigned_url);
    }

    return responses;  
  }

  async function uploadFiles() {
    activeUpload = true;
    files = uploader.files;
    let presignedUrls = await getPresignedUrls();

    for (let i = 0; i < files.length; i++) {
      const response = await fetch(presignedUrls[i], {
        method: 'PUT',
        body: files[i]
      });

      if (response.ok) {
        currentFile = i + 1;
        uploadProgress = Math.round((i + 1) / files.length * 100);
      }
    }

    currentFile = 1;
    activeUpload = false;
    displayModal = false;

    dispatch('uploadComplete');
  }
</script>

{#if (displayModal)}
  <div class='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50'>
    <div class='grid w-full h-full place-items-center'>
      <div class='grid grid-cols-1 bg-white rounded p-5'>
        {#if (activeUpload)}
          <span>Uploading Files... { currentFile } / {files.length}</span>
          <progress value={uploadProgress} max='100'>{uploadProgress} %</progress>
        {:else}
          <form on:submit|preventDefault={uploadFiles}>
            <input type='file' name='file' bind:this={ uploader } multiple/>
            <button type='submit' class='bg-sky-400 rounded p-3 mt-5 hover:bg-sky-600'>Upload</button>
          </form>
          <button class='bg-sky-400 rounded p-3 mt-5 hover:bg-sky-600' on:click={() => displayModal = false}>Close</button>
        {/if}
      </div>
    </div>
  </div>
{/if}
