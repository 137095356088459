<script>
    import Navbar from "../components/Navbar.svelte";
</script>


<div class="bg-white">
  <Navbar />
  <main>
    <div class="max-w-7xl mx-auto px-4 sm:px-8">
      <slot/>
    </div>
  </main>
</div>