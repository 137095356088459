<script>
  import { onMount } from "svelte";
  import FileUploader from "../../components/FileUploader.svelte";
  import FileViewer from "../../components/FileViewer.svelte";

  export let event;

  let displayFileUploader = false;
  let displayFileViewer = false;
  let selectedFileIndex = 0;
  let fileArray = [];

  onMount(async () => {
    await getEventMedia();
  });

  async function getEventMedia() {
    const queryParams = new URLSearchParams({ event_id: event.id });
    const response = await fetch(`/media?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    fileArray = await response.json();
  }

  function displayMediaModal(index) {
    selectedFileIndex = index;
    displayFileViewer = true;
  }
</script>

<FileUploader bind:displayModal={ displayFileUploader } {event} on:uploadComplete={ getEventMedia } />
<FileViewer bind:displayModal={ displayFileViewer } index={ selectedFileIndex } files={ fileArray } />

<div class='grid grid-cols-2 w-full'>
  <button
    class='bg-sky-400 rounded px-32 py-5 m-2 hover:bg-sky-600 col-span-2 place-self-center'
    on:click={ () => displayFileUploader = true }
  >
    Upload Photos
  </button>
  <div class='grid w-full place-items-center col-span-2'>
    <div class='grid grid-cols-1'>
      <span>{event.id}</span>
      <span>{event.name}</span>
      <span>{event.started_at}</span>
      <span>{event.completed_at}</span>
    </div>
  </div>
</div>

{#if (fileArray.length > 0)}
  <div class='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7'>
    {#each fileArray as file, index}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      {#if (file.mediatype === 'video')}
        <!-- svelte-ignore a11y-media-has-caption -->
        <video
          on:click={ () => displayMediaModal(index) }
          class='mb-3 border-2 rounded size-40 object-contain hover:border-sky-400 hover:border-4'
        >
          <source src={ file.url } type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      {:else}
        <img
          src={ file.url }
          on:click={ () => displayMediaModal(index) }
          class='mb-3 border-2 rounded size-40 object-contain hover:border-sky-400 hover:border-4'
          alt={ 'Oops' }
        />
      {/if}
    {/each}
  </div>
{/if}