<script>
  import { useForm } from '@inertiajs/svelte'

  let form = useForm({
    name: null,
    started_at: null,
    completed_at: null
  })

  function submit() {
    $form.post('/events')
  }
</script>

<div class='grid w-full place-items-center'>
  <form class='grid grid-cols-2 border-2 rounded p-5 mt-5 shadow-md' on:submit|preventDefault={submit}>
    <div class='grid grid-cols-1 col-span-2'>
      <label for='name'>Event Name</label>
      <input type="text" class='rounded' bind:value={$form.name} />
    </div>
    <div class='grid grid-cols-1 mr-1 mt-5'>
      <label for='started_at'>Starts On</label>
      <input type="date" class='rounded' bind:value={$form.started_at} />
    </div>
    <div class='grid grid-cols-1 ml-1 mt-5'>
      <label for='completed_at'>Ends On</label>
      <input type="date" class='rounded' bind:value={$form.completed_at} />
    </div>
    <button type="submit" class='col-span-2 p-3 mt-5 bg-sky-400 rounded text-white font-bold hover:bg-sky-600' disabled={$form.processing}>Submit</button>
  </form>
</div>