<script>
  export let displayModal = false;
  export let files = [];
  export let index = 0;

</script>

{#if (displayModal)}
  <div class='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50'>
    <div class='grid w-full h-full place-items-center'>
      <div class='grid grid-cols-7 bg-white rounded p-5'>
        <button
          on:click={() => index = index - 1 < 0 ? files.length - 1 : index - 1}
          class='bg-sky-400 rounded-full p-3 mt-5 hover:bg-sky-600 col-span-1 place-self-center'
        >
          {'<-'}
        </button>
        {#if (files[index].mediatype === 'video')}
          <!-- svelte-ignore a11y-media-has-caption -->
          <video controls class='border-2 rounded size-80 object-contain col-span-5'>
            <source src={ files[index].url } type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        {:else}
          <img
            src={ files[index].url }
            class='border-2 rounded size-80 object-contain col-span-5'
            alt={ 'Oops' }
          />
        {/if}
        <button
          on:click={() => index = index + 1 >= files.length ? 0 : index + 1}
          class='bg-sky-400 rounded-full p-3 mt-5 hover:bg-sky-600 col-span-1 place-self-center'
        >
          {'->'}
        </button>
        <a href={files[index].download_url} class='text-center bg-sky-400 rounded p-3 mt-3 hover:bg-sky-600 col-span-7' download>Download Full Resolution</a>
        <button class='bg-sky-400 rounded p-3 mt-1 hover:bg-sky-600 col-span-7' on:click={() => displayModal = false}>Close</button>
      </div>
    </div>
  </div>
{/if}