<script>
  import { onMount } from 'svelte';

  let canvas;

  onMount(() => {
    const ctx = canvas.getContext('2d');
    let frame;

    (function loop() {
      frame = requestAnimationFrame(loop);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      for (let p = 0; p < imageData.data.length; p += 4) {
        const i = p / 4;
        const x = i % canvas.width;
        const y = i / canvas.height >>> 0;

        const t = window.performance.now();

        const r = 64 + (128 * x / canvas.width) + (64 * Math.sin(t / 1000));
        const g = 64 + (128 * y / canvas.height) + (64 * Math.cos(t / 1400));
        const b = 128;

        imageData.data[p + 0] = r;
        imageData.data[p + 1] = g;
        imageData.data[p + 2] = b;
        imageData.data[p + 3] = 255;
      }

      ctx.putImageData(imageData, 0, 0);
    }());

    return () => {
      cancelAnimationFrame(frame);
    };
  });
</script>

<div class="flex items-center justify-center mt-8">
  <canvas
      bind:this={canvas}
      width={32}
      height={32}
      class="w-32 h-32 bg-[#666]"
  ></canvas>

  <div>
    <h1 class="text-3xl font-bold underline tracking-wide">Hello World!</h1>
    <p class="font-thin text-gray-700">My first Svelte component</p>
  </div>
</div>

<style>
    canvas {
        -webkit-mask: url(https://svelte.dev/svelte-logo-mask.svg) 50% 50% no-repeat;
        mask: url(https://svelte.dev/svelte-logo-mask.svg) 50% 50% no-repeat;
    }
</style>