<script>
  import { Link } from '@inertiajs/svelte';

  export let events;
</script>

<div>
  {#each events as event (event.id)}
    <Link
      class='grid grid-cols-2 md:grid-cols-2 border-2 rounded p-5 mt-5 shadow-md bg-sky-400 hover:bg-sky-600 rounded-lg shadow'
      href={`/events/${event.id}`}
    >
      <div class='grid col-span-1 font-bold place-self-center'>
        {event.name}
      </div>
      <div class='grid col-span-1 grid-cols-1'>
        <span><span class='font-bold'>Starts On:</span> { new Date(event.started_at).toDateString() }</span>
        <span><span class='font-bold'>Ends On:</span> { new Date(event.completed_at).toDateString() }</span>
      </div>
    </Link>
  {/each}
</div>