import '../stylesheets/main.css'

// import Layout component so we can attach the layout
import Layout from '../layouts/Layout.svelte'

// import the InertiaApp package
import {createInertiaApp} from '@inertiajs/svelte'

// import all svelte components under /pages
const pages = import.meta.glob('../pages/**/*.svelte', { eager: true })

// create the InertiaApp
createInertiaApp({
  resolve: name => {
    const page =  pages[`../pages/${name}.svelte`]
    // append layout to resolved page
    return { default: page.default, layout: page.layout || Layout }
  },
  setup({ el, App, props }) {
    // initial mount for the root (App) component
    new App({ target: el, props })
  },
})