<script>
  import { Link } from '@inertiajs/svelte'

  export let csrf_token;
  export let user;
</script>

<div class='grid w-full text-center place-items-center'>
    {#if user}
      <div class='grid grid-cols-1 md:grid-cols-2'>
        <Link href='/events/new' class='bg-sky-400 rounded p-10 m-2 hover:bg-sky-600'>Create Event</Link>
        <Link href='/events' class='bg-sky-400 rounded p-10 m-2 hover:bg-sky-600'>View Events</Link>
      </div>
    {:else}
      <div class='grid grid-cols-1'>
        <h1 class='grid-span-2'>Welcome to Timestamp!  Please Click the button below to login:</h1>
        <form method='post' action='/auth/auth0'>
          <input type='hidden' name="authenticity_token" value={csrf_token} />
          <button type="submit" class='col-span-2 p-3 mt-5 bg-sky-400 rounded text-white font-bold hover:bg-sky-600'>Login</button>
        </form>
      </div>
    {/if}
</div>